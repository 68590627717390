<template>
    <div>
        
        <v-card-text>
            <v-form @submit.prevent="updateSerology">
                <v-container>
                    <p style="font-weight:bold" v-if="((this.$store.state.profile_id == 5))">Técnico de Hemoba:</p>
                    <v-row v-if="((this.$store.state.profile_id == 5))">
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-select 
                            :rules="formRules"
                            :items="hemoba_profiles"
                            color="#256936" 
                            v-model="serologyForm.hemoba_profiles_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="Tipo de Perfil">
                            </v-select>
                        </v-col>
                    </v-row>

                    <p style="font-weight:bold">Doador:</p>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="3" v-if="this.serologyForm.donor_id">
                            <v-select 
                            :rules="formRules"
                            :items="donor"
                            color="#256936" 
                            v-model="serologyForm.donor_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            disabled
                            label="Paciente/Provável Doador">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field 
                            color="#256936" 
                            :rules="formRules" 
                            label="Registro"
                            outlined
                            v-model="serologyForm.register" 
                            maxlength="14" 
                            v-mask="'##############'"
                            dense></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field 
                            color="#256936" 
                            :rules="formRules" 
                            label="Nº do Laudo/AAAA"
                            outlined
                            v-model="serologyForm.report_number" 
                            dense></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field 
                            color="#086318" 
                            :rules="formRules" 
                            label="Data da Coleta de Amostra"
                            type="date" 
                            outlined 
                            v-model="serologyForm.sample_date"
                            dense></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <p style="font-weight:bold">Tipo de Laudo:</p>
                    <v-row style="padding-left:10px">
                        <v-radio-group :rules="formRules" v-model="serologyForm.type_report_id">
                            <v-radio label="Definitivo" 
                            value="1" 
                            color="#086318"
                            ></v-radio>

                            <v-radio label="Provisório" 
                            value="2" 
                            color="#086318"
                            ></v-radio>
                        </v-radio-group>
                    </v-row>
                    <br>
                    <p style="font-weight:bold">Resultado dos Exames Laboratoriais:</p>
                        <p>Grupo Sanguíneo:</p>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field 
                            color="#256936" 
                            :rules="formRules" 
                            label="ABO"
                            outlined
                            v-model="serologyForm.abo" 
                            dense></v-text-field>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="3">
                            <v-text-field 
                            color="#256936" 
                            :rules="formRules" 
                            label="Rh"
                            outlined
                            v-model="serologyForm.rh" 
                            dense></v-text-field>
                        </v-col>
                    </v-row>

                    <p class="subtitle">Sorologia:</p>

                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.sifilis_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="Sífilis">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.chagas_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="Chagas">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.aghbs_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="AgHBs">
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.anti_hbc_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="ANTI-HBc">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.hcv1_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="HCV (1ª Metodologia)">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.hcv2_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="HCV (2ª Metodologia)">
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.hcv_nat_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="HCV NAT">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.anti_hbs_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="ANNTI-HBs">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.htlv_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="HTLV">
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.hiv1_2_1_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="HIV1+2 (1ª Metodologia)">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.hiv1_2_2_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="HIV1+2 (2ª Metodologia)">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.hiv_nat_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="HIV NAT">
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.t_plasmosis_lgg_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="Toxoplasmose lgG">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.t_plasmosis_lgm_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="Toxoplasmose lgM">
                            </v-select>
                        </v-col>

                        <v-col class="d-flex" cols="12" sm="4">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.cmv_lgg_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="CMV lgG">
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-select 
                            :rules="formRules"
                            :items="status_serology"
                            color="#256936" 
                            v-model="serologyForm.cmv_lgm_id"
                            item-value="id"
                            item-text="name"  
                            dense 
                            outlined 
                            label="CMV lgM">
                            </v-select>
                        </v-col>
                    </v-row>

                    <p style="font-weight:bold">Anexos:</p>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="5">
                            <v-file-input
                                v-model="serologyAttachments.attachment"
                                label="Documento em PDF"
                                class="borderRadius10"
                                color="black"
                                append-icon="mdi-file"
                                prepend-icon=""
                                show-size
                                outlined
                                dense>
                            </v-file-input>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-text-field
                            color="#086318" 
                            label="Descrição" 
                            :rules="formRules" 
                            outlined
                            v-model="serologyAttachments.attachment_name" 
                            dense></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-tooltip right color="green">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mx-1" small fab dark color="green" v-bind="attrs" v-on="on" @click="addAttachment()">
                                        <v-icon dark> mdi-plus </v-icon>
                                    </v-btn>
                                </template>
                            <span style="color:white">Inserir Anexo</span>
                            </v-tooltip>
                            <p class="space"></p>
                        </v-col>
                    </v-row>

                    <template>
                        <v-data-table
                            :headers="headers"
                            :items="arquivos"
                            :items-per-page="5"
                            class="elevation-1">
                            <template v-slot:item.actions="{ item }">
                                <v-icon 
                                size="20"
                                class="icon"
                                color="red" 
                                align-item="center"
                                @click="deleteAttachment(item)">
                                mdi-trash-can-outline
                                </v-icon>
                            </template>
                        </v-data-table>
                    </template>

                    <p class="space-personalized"></p>
                    <div class="buttons">
                        <v-row>
                            <v-btn class="rounded-lg" elevation="0" type="submit" @click="updateSerology()" color="green">
                            <span class="textBtn"> Atualizar Sorologia</span>
                            </v-btn>
                        </v-row>
                    </div>
                </v-container>
            </v-form> 
        </v-card-text>
        <v-snackbar absolute background-color="white" color="green" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
  
<script>
import axios from "@/axios";
import FormatDate from "@/utils/FormatDate"

export default {
    data() {
        return {
            dialog: false,
            displayError: {
                display: false,
                message: [],
            },
            serologyForm: {
                donor_id: "",
                user_id:""
            },
            serologyAttachments:{
                attachments_type_id: 2
            },
            headers: [
                {
                    text: "Descrição",
                    align: "center",
                    value: "attachment_name",
                },
                { 
                    text: "Ações", 
                    value: "actions", 
                    align: "center",
                    sortable: false 
                }
            ],
            selectedDonor: [],
            hemoba_profiles: [],
            arquivos: [],
            donor_id: "",
            loading: true,
            options: {},
            donor: [],
            status_serology: [],
            message: "",
            snackbar: false,
            unity: "",
            // id: localStorage.getItem('donorId'),
            id: "",
            formRules: [
             (v) => !!v || "Este campo é obrigatório",
            ],
        };
    },

    props: {
        glasgowId: {
            type: Number
        },
    },

    created() {
        this.loadTable()
        if(this.$store.state.profile_id == 5){
            this.$route.query.item.aghbs_id = parseInt(this.$route.query.item.aghbs_id);
            this.$route.query.item.anti_hbc_id = parseInt(this.$route.query.item.anti_hbc_id);
            this.$route.query.item.anti_hbs_id = parseInt(this.$route.query.item.anti_hbs_id);
            this.$route.query.item.chagas_id = parseInt(this.$route.query.item.chagas_id);
            this.$route.query.item.cmv_lgg_id = parseInt(this.$route.query.item.cmv_lgg_id);
            this.$route.query.item.cmv_lgm_id = parseInt(this.$route.query.item.cmv_lgm_id);
            this.$route.query.item.donor_id = parseInt(this.$route.query.item.donor_id);
            this.$route.query.item.hcv1_id = parseInt(this.$route.query.item.hcv1_id);
            this.$route.query.item.hcv2_id = parseInt(this.$route.query.item.hcv2_id);
            this.$route.query.item.hcv_nat_id = parseInt(this.$route.query.item.hcv_nat_id);
            this.$route.query.item.hiv1_2_1_id = parseInt(this.$route.query.item.hiv1_2_1_id);
            this.$route.query.item.hiv1_2_2_id = parseInt(this.$route.query.item.hiv1_2_2_id);
            this.$route.query.item.hiv_nat_id = parseInt(this.$route.query.item.hiv_nat_id);
            this.$route.query.item.htlv_id = parseInt(this.$route.query.item.htlv_id);
            this.$route.query.item.sifilis_id = parseInt(this.$route.query.item.sifilis_id);
            this.$route.query.item.t_plasmosis_lgg_id = parseInt(this.$route.query.item.t_plasmosis_lgg_id);
            this.$route.query.item.t_plasmosis_lgm_id = parseInt(this.$route.query.item.t_plasmosis_lgm_id);
            this.$route.query.item.sample_date = FormatDate.dataAtualFormatada(this.$route.query.item.sample_date);
            this.serologyForm = { ...this.$route.query.item };
        }else{
            this.glasgowId ? this.id = this.glasgowId : this.id = localStorage.getItem('donorId')

            axios.get(`/serology/get/${this.id}`).then((response) => {
                this.serologyForm = response.data[0];
                this.serologyForm.id = parseInt(this.serologyForm.id);
                this.serologyForm.register = parseInt(this.serologyForm.register);
                this.serologyForm.report_number = parseInt(this.serologyForm.report_number);
                //this.serologyForm.type_report_id = this.serologyForm.type_report_id;
                // this.serologyForm.abo = parseInt(this.serologyForm.abo);
                // this.serologyForm.rh = parseInt(this.serologyForm.rh);
                this.serologyForm.aghbs_id = parseInt(this.serologyForm.aghbs_id);
                this.serologyForm.anti_hbc_id = parseInt(this.serologyForm.anti_hbc_id);
                this.serologyForm.anti_hbs_id = parseInt(this.serologyForm.anti_hbs_id);
                this.serologyForm.chagas_id = parseInt(this.serologyForm.chagas_id);
                this.serologyForm.cmv_lgg_id = parseInt(this.serologyForm.cmv_lgg_id);
                this.serologyForm.cmv_lgm_id = parseInt(this.serologyForm.cmv_lgm_id);
                this.serologyForm.donor_id = parseInt(this.serologyForm.donor_id);
                this.serologyForm.hcv1_id = parseInt(this.serologyForm.hcv1_id);
                this.serologyForm.hcv2_id = parseInt(this.serologyForm.hcv2_id);
                this.serologyForm.hcv_nat_id = parseInt(this.serologyForm.hcv_nat_id);
                this.serologyForm.hiv1_2_1_id = parseInt(this.serologyForm.hiv1_2_1_id);
                this.serologyForm.hiv1_2_2_id = parseInt(this.serologyForm.hiv1_2_2_id);
                this.serologyForm.hiv_nat_id = parseInt(this.serologyForm.hiv_nat_id);
                this.serologyForm.htlv_id = parseInt(this.serologyForm.htlv_id);
                this.serologyForm.sifilis_id = parseInt(this.serologyForm.sifilis_id);
                this.serologyForm.t_plasmosis_lgg_id = parseInt(this.serologyForm.t_plasmosis_lgg_id);
                this.serologyForm.t_plasmosis_lgm_id = parseInt(this.serologyForm.t_plasmosis_lgm_id);
                this.serologyForm.sample_date = FormatDate.dataAtualFormatada(this.serologyForm.sample_date);
            });
        }
        axios.get(`/donor`).then((response) => { this.donor = response.data.data;});
         axios.get(`/serology_status`).then((response) => { this.status_serology = response.data.data;});
            axios.get(`/hemoba_profiles`).then((response) => {this.hemoba_profiles = response.data.data;});
    },

    watch: {
        options: {
            handler() {
                this.indoDataTable();
            },
        },
        deep: true,
    },

    methods: {
        indoDataTable() {
            this.loading = true;

            if (this.$store.state.profile_id == 1) {
                axios.get(`/donor`).then((response) => {
                    this.loading = false;
                    this.donor = response.data.data;
                });
            } else {
                const id = this.$store.state.unity_id
                axios.get(`/donor/donorUnity/${id}`).then((response) => {
                    this.loading = false;
                    this.donor = response.data
                })
            }
        },

        updateSerology() {
                this.serologyForm.user_id = 2
                this.serologyForm.donor_id = parseInt(this.id)

                // this.serologyForm.digital_signature_id = 8
                const serology = (this.serologyForm);
                axios
                    .put(`/serology/${serology.id}`, serology)
                    .then((response) => {
                        this.indoDataTable();
                        this.message = response.data.message;
                        this.snackbar = true;
                        this.dialog = false;
                   
                    })
                    .catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                        this.displayError.message = error.toString();
                        this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                });
        },

        loadTable(){
            axios.get("/attachments").then((response) => {
                this.arquivos = response.data.data;
                this.arquivos = this.arquivos.filter((x) => x.donor_id == this.id);
            });
        },

        deleteAttachment(item){
        const attachmentId = item.id
            axios.delete(`/attachments/${attachmentId}`).then((response) => {
                this.loading = true;
                this.message = response.data.message;
                this.snackbar = true;
                this.loadTable();
            });
        },

        addAttachment(){
            let anexosData = new FormData();
            anexosData.append("donor_id", this.serologyForm.donor_id)
            anexosData.append("attachment_name", this.serologyAttachments.attachment_name)
            anexosData.append("attachment", this.serologyAttachments.attachment)
            anexosData.append("attachments_type_id", this.serologyAttachments.attachments_type_id)
                axios.post(`/attachments/create`, anexosData)
                        .then((response) => {
                            this.message = response.data.message;
                            this.snackbar = true;
                            this.dialog = false;
                            this.serologyAttachments.attachment_name = "";
                            this.serologyAttachments.attachment = "";
                            this.loadTable();
                        })
                        .catch((err) => {
                            err;
                            this.messageError = "Insira um arquivo PDF.";
                            this.snackbar_error = true;
                            this.dialog = false;
                            var error = "";
                            Object.values(this.$store.state.errorMessage).map((value) => {
                                error = value;
                            });
                            this.displayError.message = error.toString();
                            this.displayError.display = true;
                            setTimeout(() => {
                                this.displayError.display = false;
                            }, 3000);
                        }).finally(() => {
                    });
        }
    },

    mounted() {
        this.indoDataTable();
    },

};
</script>
  
<style scoped>
.header-table {
    display: flex;
    margin-bottom: 20px;
}

p{
    font-size: 16px;
    /* font-weight: bold; */
}
.d-flex {
    padding: 0 12px;
}

.space {
    width: 5px;
}

.space-personalized {
    padding: 4px;
}

.spacediferent {
    width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.create-user-dialog {
    padding: 20px;
}

.card {
    padding: 35px;
}

.banner {
    background: #b3ffab;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
            #27ae60,
            #1abc9c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #ee106d,
            #e46987);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 8px;

    margin-bottom: 20px;

    display: flex;
    align-items: center;
}

.icon {}

.banner>h4 {
    color: #ffffff;
    padding: 10px;
}

 .cardFooter {
    padding: 0 !important;
}
</style>
  