<template>
  <div>
      <v-card class="rounded-lg" elevation="1">
          <v-tabs fixed-tabs background-color="#50c78f" dark>
              <v-tabs-slider color="#b1e3cc"></v-tabs-slider>
              <v-tab>EDITAR BUSCA ATIVA</v-tab>
              <!-- <v-tab>EDITAR SOROLOGIA</v-tab> -->

              <v-tab-item>
                  <v-card-text>
                      <v-form @submit.prevent="activeSearchForm" ref="form">
                          <v-container>
                              <p style="font-weight:bold"> Possível Doador:</p>
                              <v-row>
                                  <v-col class="d-flex" cols="12" sm="4">
                                      <v-text-field 
                                      color="#256936" 
                                      :rules="formRules" 
                                      label="Nome Completo"
                                      outlined
                                      v-model="activeSearch.name" 
                                      dense></v-text-field>
                                  </v-col>

                                  <v-col class="d-flex" cols="12" sm="2">
                                      <v-text-field 
                                      color="#256936" 
                                      :rules="formRules" 
                                      label="CPF"
                                      outlined
                                      v-model="activeSearch.cpf" 
                                      maxlength="14" 
                                      v-mask="'###.###.###-##'"
                                      dense></v-text-field>
                                  </v-col>

                                  <v-col class="d-flex" cols="12" sm="2">
                                      <v-select 
                                      :items="sex"
                                      :rules="formRules"
                                      color="#086318" 
                                      v-model="activeSearch.sex_id"
                                      item-value="id"
                                      item-text="name"  
                                      dense 
                                      outlined 
                                      label="Gênero">
                                      </v-select>
                                  </v-col>

                                  <v-col class="d-flex" cols="12" sm="2">
                                      <v-text-field 
                                      color="#256936" 
                                      :rules="formRules" 
                                      label="RG"
                                      outlined
                                      v-model="activeSearch.rg" 
                                      maxlength="13" 
                                      v-mask="'##.###.###-##'"
                                      dense></v-text-field>
                                  </v-col>

                                  <v-col class="d-flex" cols="12" sm="2">
                                      <v-text-field 
                                      color="#086318" 
                                      :rules="formRules" 
                                      label="Data de Nascimento"
                                      type="date" 
                                      outlined 
                                      v-model="activeSearch.birth_date"
                                      dense></v-text-field>
                                  </v-col>
                              </v-row>
                              <v-row>
                                  <v-col class="d-flex" cols="12" sm="4">
                                      <v-text-field 
                                      color="#256936" 
                                      :rules="formRules" 
                                      label="Diagnóstico Inicial"
                                      outlined
                                      v-model="activeSearch.initial_diagnosis" 
                                      dense></v-text-field>
                                  </v-col>
                                  <v-col class="d-flex" cols="12" sm="3">
                                        <v-text-field 
                                        color="#256936" 
                                        :rules="formRules" 
                                        label="Número de Prontuário"
                                        outlined
                                        type="number"
                                        v-model="activeSearch.medical_records" 
                                        dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-text-field 
                                        color="#256936" 
                                        :rules="formRules" 
                                        label="Localização"
                                        outlined
                                        v-model="activeSearch.location" 
                                        dense></v-text-field>
                                    </v-col>
                                  <v-col class="d-flex" cols="12" sm="2">
                                      <v-btn class="rounded-lg" elevation="2" color="#50c78f" @click="updateDonor()">
                                      <span class="textBtn">Atualizar</span>
                                      </v-btn>
                                  </v-col>
                              </v-row>
                          </v-container>
                      </v-form>

                      <v-form @submit.prevent="activeSearchForm" ref="form">
                          <v-container>
                              <p class="space-personalized"></p>
                              <p style="font-weight:bold" v-if="this.activeSearch.is_donor == null">Escala de Glasgow:</p>
                              <v-row v-if="this.activeSearch.is_donor == null">
                                  <v-col class="d-flex" cols="12" sm="2">
                                      <v-text-field 
                                      color="#256936" 
                                      label="Informe aqui"
                                      outlined
                                      v-model="glasgowScale.description" 
                                      dense></v-text-field>
                                  </v-col>
                                  <v-col class="d-flex" cols="12" sm="5">
                                        <v-text-field 
                                        color="#256936" 
                                        label="Evolução"
                                        outlined
                                        v-model="glasgowScale.evolution" 
                                        dense></v-text-field>
                                    </v-col>
                                  <v-col class="d-flex" cols="12" sm="2" v-if="enableEditButton == false">
                                      <v-btn class="mx-1"  small fab dark color="#50c78f" @click="addNewGlasgowScale()">
                                          <v-icon dark>mdi-plus</v-icon>
                                      </v-btn>
                                  </v-col>
                                  <v-col class="d-flex" cols="12" sm="2" v-if="enableEditButton == true">
                                        <v-tooltip right color="#236947">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="mx-1"  small fab dark color="#236947" v-bind="attrs" v-on="on" @click="editScale()">
                                                    <v-icon dark>mdi-check</v-icon>
                                                </v-btn>
                                            </template>
                                        <span style="color:white">Salvar alteração</span>
                                        </v-tooltip>
                                    </v-col>
                              </v-row>
                              <br>
                              <template>
                                  <v-data-table
                                      :headers="headers"
                                      :items="glasgow"
                                      :items-per-page="10"
                                      class="elevation-1"
                                  >
                                  <template v-slot:item.actions="{ item }" :resource="item">
                                    <v-tooltip bottom color="#50c78f">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon 
                                                size="20"
                                                class="icon"
                                                color="#50c78f" 
                                                v-on="on"
                                                v-bind="attrs"
                                                align-item="center"
                                                @click="editGlasgowScale(item)">
                                                mdi-pen
                                            </v-icon>
                                        </template>
                                        <span style="color:white">Editar</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="red">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon 
                                                size="20"
                                                class="icon"
                                                color="red" 
                                                v-on="on"
                                                v-bind="attrs"
                                                align-item="center"
                                                @click="deleteGlasgowScale(item)">
                                                mdi-trash-can-outline
                                            </v-icon>
                                        </template>
                                        <span style="color:white">Excluir</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                        </template>

                        <v-dialog persistent width="400px" v-model="dialogPermissionToDelete">
                                <v-card class="deleteDialog">
                                    <v-card-title class="text-h7">
                                        <v-icon dark class="donorIcon">mdi-trash-can</v-icon>
                                      Deletar
                                    </v-card-title>
                                    <h4>Seu perfil de acesso tem permissão para deletar registros feitos por outros usuários. Realmente deseja fazer isso? Não poderá ser desfeito.</h4>
                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="#ed695a"
                                            variant="text"
                                            value="Não"
                                            @click="dialogPermissionToDelete = false"
                                        >
                                            Não
                                        </v-btn>
                                        <v-btn
                                            color="#8fdb8f"
                                            variant="text"
                                            value="Sim"
                                            @click="deleteScale()"
                                        >
                                            Sim
                                        </v-btn>
                                        </v-card-actions>
                                </v-card>
                            </v-dialog>

                            <v-dialog persistent width="auto" v-model="dialogPermissionDenied">
                                    <v-card class="deniedAcessDialog">
                                        <v-card-title class="text-h7">
                                            <v-icon dark class="donorIcon">mdi-alert-remove</v-icon>
                                        Não autorizado
                                        </v-card-title>
                                        <h4>Você não tem permissão para apagar registros de outras pessoas.</h4>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="#ed695a"
                                                    variant="text"
                                                    value="Não"
                                                    @click="dialogPermissionDenied = false">
                                                    Fechar
                                                </v-btn>
                                            </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-dialog persistent width="800px" v-model="dialogDeath">
                                    <v-card class="deathDialog">
                                        <v-card-title class="text-h5">
                                            Paciente: {{ activeSearch.name }}
                                        </v-card-title>
                                        <h4>{{ activeSearch.name }} evoluiu para morte encefálica?</h4>
                                            <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="#ed695a"
                                                variant="text"
                                                value="Não"
                                                @click="recusePatient()"
                                            >
                                                Não
                                            </v-btn>
                                            <v-btn
                                                color="#8fdb8f"
                                                variant="text"
                                                value="Sim"
                                                @click="activeDialogDonor()"
                                            >
                                                Sim
                                            </v-btn>
                                            </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-dialog persistent width="500px" v-model="dialogDonor">
                                    <v-card class="donorDialog">
                                        
                                        <v-card-title class="text-h5">
                                            <v-icon dark class="donorIcon">mdi-account-plus</v-icon>
                                            Cadastrar como doador? 
                                        </v-card-title>
                                        <h4>Enviar dados de {{ activeSearch.name }} para o cadastro de doador?</h4>
                                            <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="#ed695a"
                                                variant="text"
                                                value="Não"
                                                @click="$router.push('/busca-ativa')"
                                            >
                                                Não
                                            </v-btn>
                                            <v-btn
                                                color="#8fdb8f"
                                                variant="text"
                                                value="Sim"
                                                @click="sendToDonor()"
                                            >
                                                Sim
                                            </v-btn>
                                            </v-card-actions>
                                    </v-card>
                                </v-dialog>
                              <p class="space-personalized"></p>

                              <div class="buttons">
                                  <v-row>
                                    <v-btn class="rounded-lg" elevation="0" color="#50c78f"  v-if="this.activeSearch.kind_of_donor_id == 1" @click="$router.push('/busca-ativa')">
                                            <span class="textBtn">Atualizar</span>
                                        </v-btn>
                                        <p class="space-personalized"></p>
                                        <v-btn class="rounded-lg" elevation="0" color="#40a374" v-if="this.activeSearch.kind_of_donor_id == 1" @click="activeDialog()">
                                            <span class="textBtn">concluir cadastro</span>
                                        </v-btn>
                                        <p class="space-personalized"></p>
                                      <v-btn class="rounded-lg" elevation="0" color="grey"  @click="$router.push('/busca-ativa')">
                                          <span class="textBtn">Sair</span>
                                      </v-btn>
                                  </v-row>
                              </div>
                          </v-container>
                      </v-form>
                  </v-card-text>
              </v-tab-item>

              <v-tab-item>
                <EditarSorologia :glasgowId="donorId"/>
              </v-tab-item>
          </v-tabs>
      </v-card>
      <v-snackbar absolute background-color="white" color="#236947" v-model="snackbar">
          {{ message }}
          <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              Fechar
              </v-btn>
          </template>
      </v-snackbar>

      <v-snackbar absolute background-color="white" color="#9e212e" v-model="snackbarLimit">
          {{ message }}
          <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbarLimit = false">
              Fechar
              </v-btn>
          </template>
      </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import FormatDate from "@/utils/FormatDate"
import EditarSorologia from "../pacientes/doador/sorologia/EditarSorologia-comp.vue";
export default{
  data() {
      return {
          headers: [
          {
            text: "Escala de Glasgow",
            value: "description",
            align: "center",
          },
          {
            text: "Responsável pela análise",
            value: "users.name",
            align: "center",
          },
          {
              text: "Evolução",
              value: "evolution",
              align: "center",
        },
          {
            text: "Data",
            value: "created_at",
            align: "center",
          },
          {
            text: "Ações",
            align: "center",
            value: "actions",
          }
          ],
          dialog: false,
          dialogDonor: false,
          dialogDeath: false,
          disabledDonorForm: false,
          disableScaleForm: true,
          dialogPermissionToDelete: false,
          dialogPermissionDenied: false,
          activateButtons: false,
          enableEditButton: false,
          displayError: {
              display: false,
              message: [],
          },
          activeSearch: {
              unity_id: "",
              hospital_id: "",
              donor_status_id: 1
          },
          glasgowScale: {
              donor_id: "",
              user_id: "",
          },
          evolutionForm: {
            description: "",
            donor_id: "",
            glasgow_scale_id: ""
          },
          itemToDelete: {},
          glasgow: [],
          donor_id: "",
          donorIdSaved: "",
          donorId:"",
          itemScaleID: "",
          newScales: false,
          newDonor: true,
          loading: true,
          options: {},
          donor: [],
          status_serology: [],
          message: "",
          snackbar: false,
          snackbarLimit: false,
          messageError: "",
          unity: "",
          description: "",
          rg:"",
          sex: [],
          evolution:[],
          evolutionDialog: false,
          formRules: [
           (v) => !!v || "Este campo é obrigatório",
          ],
      };
  },

  components: {
    EditarSorologia,
  },

  created() {
      this.$route.query.item.sex_id = parseInt(this.$route.query.item.sex_id)
      this.$route.query.item.birth_date = FormatDate.dataAtualFormatada(this.$route.query.item.birth_date)
      this.activeSearch={...this.$route.query.item}
      this.donorId=this.activeSearch.id
      axios.get(`/glasgow_scale/donor/${this.donorId}`).then((response) => {
          this.glasgow = response.data;
      });
      axios.get(`/donor`).then((response) => { this.donor = response.data.data;});
      axios.get("/sex").then((response) => {this.sex = response.data.data;});
      
  },

  watch: {
      options: {
          handler() {
              this.indoDataTable();
          },
      },
      deep: true,
  },

  methods: {
      indoDataTable() {
          this.loading = true;
      },

      indoGlasgowTable() {
          axios.get(`/glasgow_scale/donor/${this.donorId}`).then((response) => {
              this.glasgow = response.data;
          });
      },

      //Atualiza o doador
      updateDonor(){
        if(this.$refs.form.validate() ) {
            this.activeSearch.unity_id = this.$store.state.unity_id
            this.activeSearch.hospital_id = this.$store.state.unity_id
              const donor = (this.activeSearch);
            axios
                .patch(`/donor/${donor.id}`, donor)
                    .then((response) => {
                        this.message = response.data.message;
                        this.snackbar = true;
                        this.dialog = false;
                        this.disabledDonorForm = true;
                        this.disableScaleForm = false;
                        this.newDonor = false;
                        this.newScales = true;
                    })
                .catch((err) => {
                err;
                var error = "";
                Object.values(this.$store.state.errorMessage).map((value) => {
                    error = value;
                });
                    this.displayError.message = error.toString();
                    this.displayError.display = true;
                setTimeout(() => {
                    this.displayError.display = false;
                }, 3000);
            })
        }
      },
    
      recusePatient(){
            this.activeSearch.is_donor = "não"
            const donor = this.activeSearch
            axios
            .patch(`/donor/${this.donorId}`, donor)
                .then(() => {
                    setTimeout(() => {
                        this.$router.push({ path: '/busca-ativa'});
                    }, "1000");
                }).catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
            });
                this.displayError.message = error.toString();
                this.displayError.display = true;
            setTimeout(() => {
                this.displayError.display = false;
            }, 3000);
        })
        },

      //Adicionando mais uma escala
      addNewGlasgowScale(){
          this.glasgowScale.user_id = this.$store.state.authUser.id
          this.glasgowScale.donor_id = this.donorId
          const glasgowScale = this.glasgowScale
          axios
              .post(`/glasgow_scale`, glasgowScale)
                  .then((response) => {
                      this.message = response.data.message;
                      this.snackbar = true;
                      this.dialog = false;
                      this.glasgowScale.description = ""
                      this.glasgowScale.evolution = ""
                      this.activateButtons = true
                      this.disableScaleForm = true
                      this.indoGlasgowTable();
                  })
              .catch((err) => {
                  err;
                  var error = "";
                  Object.values(this.$store.state.errorMessage).map((value) => {
                      error = value;
                  });
                      this.displayError.message = error.toString();
                      this.displayError.display = true;
                  setTimeout(() => {
                      this.displayError.display = false;
                  }, 3000);
          })

        },

        editGlasgowScale(item){
            this.enableEditButton = true
            this.glasgowScale.evolution = item.evolution
            this.glasgowScale.description = item.description
            this.enableEditButton = true
            this.itemScaleID = item.id
        },
        
        editScale(){
            this.glasgowScale.donor_id = this.donorId
            this.glasgowScale.user_id = this.$store.state.authUser.id
            const scale = this.glasgowScale
            let id = this.itemScaleID
            axios
            .put(`/glasgow_scale/${id}`, scale)
            .then((response) => {
                this.indoGlasgowTable();
                this.enableEditButton = false
                this.glasgowScale.description = ""
                this.glasgowScale.evolution = ""
                this.disableButtonScaleForm = false
                this.message = response.data.message;
                if(this.message === "Você excedeu o limite de 15 minutos para edição."){
                    this.snackbarLimit = true
                    this.dialog = false;
                }else{
                    this.snackbar = true;
                    this.dialog = false;
                }
            })
            .catch((err) => {
                err;
                var error = "";
                Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
                });

                this.displayError.message = error.toString();
                this.displayError.display = true;
                setTimeout(() => {
                this.displayError.display = false;
                }, 3000);
            });
        },

        activeDialog(){
            this.dialogDeath = true
        },

        activeDialogDonor(){
            this.dialogDeath = false
            this.dialogDonor = true
        },

        sendToDonor(){
            this.activeSearch.kind_of_donor_id = 2
            this.activeSearch.donor_status_id = 2
            const donor = this.activeSearch
            axios
            .patch(`/donor/${donor.id}`, donor)
                .then(() => {
                    this.$router.push({
                        path: '/cadastrar-doador', 
                        query: {item:this.activeSearch}
                    });
                }).catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
            });
                this.displayError.message = error.toString();
                this.displayError.display = true;
            setTimeout(() => {
                this.displayError.display = false;
            }, 3000);
        })
            // this.$router.push({
            //     path: '/cadastrar-doador', 
            //     query: {item:this.activeSearch}
            // });
        },

        deleteGlasgowScale(item){
            if(item.user_id == this.$store.state.authUser.id){
                axios.delete(`/glasgow_scale/${item.id}`).then((response) => {
                    this.loading = true;
                    this.message = response.data.message;
                    this.snackbar = true;
                    this.indoGlasgowTable();
                    this.disableScaleForm = false
                })
            }else if(item.user_id != this.$store.state.authUser.id && this.$store.state.profile_id == 1){
               this.loading = true;
               this.itemToDelete = item;
               this.dialogPermissionToDelete = true;
            }else if(item.user_id != this.$store.state.authUser.id && this.$store.state.profile_id == 4){
                this.loading = true;
                this.itemToDelete = item;
                this.dialogPermissionToDelete = true;
            }else{
                this.loading = true;
                this.dialogPermissionDenied = true;
            }
      },

      deleteScale(){
        const item = this.itemToDelete;
        axios.delete(`/glasgow_scale/${item.id}`).then((response) => {
            this.dialogPermissionToDelete = false;
            this.loading = true;
            this.message = response.data.message;
            this.snackbar = true;
            this.indoGlasgowTable();
            this.disableScaleForm = false
        })
      }

  },

  mounted() {
      this.indoDataTable();
  },

};
</script>

<style scoped>
.deathDialog>h4{
    color: #413f3f;
    padding: 20px;
}
.donorDialog>h4{
    color: #413f3f;
    padding: 20px;
}
.deleteDialog>h4{
    color: #413f3f;
    padding: 20px;
}
.deniedAcessDialog>h4{
    color: #413f3f;
    padding: 20px;
}
.donorIcon{
    padding:  8px 8px 8px 0;
}
.text-h5{
    background-color: #50c78f;
    color: white;
    text-shadow: 1px 2px 2px #309171
}
.text-h7{
    background-color: #d61e0d;
    color: white;
    text-shadow: 1px 2px 2px #7b1313
}

.header-table {
  display: flex;
  margin-bottom: 20px;
}

p{
  font-size: 16px;
  /* font-weight: bold; */
}
.d-flex {
  padding: 0 12px;
}

.space {
  width: 5px;
}

.space-personalized {
  padding: 4px;
}

.spacediferent {
  width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}
.banner {
  background: #b3ffab;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right,
      #0ca673,
      #1abc9c);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #0cb087,
      #66f9b9);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.textBtn{
  color:#ffffff;
}
.banner>h3 {
  color: #ffffff;
  padding: 18px 0 18px 0;
}
.modalIcon{
  padding: 12px;
}

.icon {}

.banner>h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
