export default {
  dataAtualFormatada(date) {
    let data = new Date(date);
    let dia = data.getDate().toString();
    let diaF = dia.length == 1 ? "0" + dia : dia;
    let mes = (data.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
    let mesF = mes.length == 1 ? "0" + mes : mes;
    let anoF = data.getFullYear();
    return anoF + "-" + mesF + "-" + diaF;
  },

  dataAtualFormatadaRelatorio(date) {
    let data = new Date(date);
    let dia = data.getDate().toString();
    let diaF = dia.length == 1 ? "0" + dia : dia;
    let mes = (data.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
    let mesF = mes.length == 1 ? "0" + mes : mes;
    let anoF = data.getFullYear();
    return diaF + "-" + mesF + "-" + anoF;
  },

  dataBrSemMascara(date) {
    let data = new Date(date);
    let dia = data.getDate().toString();
    let diaF = dia.length == 1 ? "0" + dia : dia;
    let mes = (data.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
    let mesF = mes.length == 1 ? "0" + mes : mes;
    let anoF = data.getFullYear();
    return diaF+mesF+anoF;
  },

  replace(data) {
    return data.replace(/[^a-zA-Z0-9]/g,"");
}
};
